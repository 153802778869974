import PropTypes from "prop-types"
import React, { useState } from "react"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link } from "react-router-dom"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// actions
import { loginUser } from "../../store/actions"

// import images
import profile from "assets/images/loginScreen.png"
import logo from "assets/images/logo.svg"
import { showCompany } from "helpers/Settings/Company"
import { NotificationMessage, apiErrorrHandler, encryptText } from "utils"

const Login = props => {
  //meta title
  document.title = "Login"

  const dispatch = useDispatch()
  // states

  const [showPassword, setShowPassword] = useState(false)

  // Calling for view company endpoint
  // to get the currency from it after login
  const getCompany = async () => {
    try {
      const response = await showCompany()
      const mainCurrency = response?.item?.main_currency
      localStorage.setItem(
        "TPACurrency",
        encryptText(JSON.stringify(mainCurrency))
      )
    } catch (error) {
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("Error", errorMessage)
    }
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
      remember_me: false,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid Email Format")
        .required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
      remember_me: Yup.boolean(),
    }),
    onSubmit: values => {
      dispatch(loginUser(values, props.history, () => getCompany()))
    },
  })

  const { error, loading } = useSelector(state => ({
    error: state.Login.error,
    loading: state.Login.loading,
  }))

  return (
    <React.Fragment>
      {/* Home Icon On the Right Top Corner */}

      <div className="home-btn d-none d-sm-block">
        {/* Link To Home Page */}
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>

      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to Aces TPA System.</p>
                      </div>
                    </Col>

                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>

                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>

                  <div className="p-2">
                    {/* Form */}

                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      {/* Signing Error Message */}

                      {error ? (
                        <Alert color="danger">
                          {typeof error === "object" && error !== null
                            ? "Incorrect Email or Password"
                            : error}
                        </Alert>
                      ) : null}

                      {/* Email Input */}

                      <div className="mb-3">
                        <Label className="form-label">Username</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter Username"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />

                        {/* Username Error Message */}

                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>

                      {/* Password Input  */}

                      <div className="mb-3 position-relative">
                        <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type={showPassword ? "text" : "password"}
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />

                        {/* Show Password Icon */}

                        {validation.values.password.length > 0 ? (
                          <span
                            className="showPasswordIconContainer"
                            onClick={() =>
                              setShowPassword(showPassword => !showPassword)
                            }
                          >
                            <i className="mdi mdi-eye-outline font-size-18"></i>
                          </span>
                        ) : null}

                        {/* Password Error Message */}

                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                          disabled={loading}
                        >
                          {props.t("Login")}
                        </button>
                      </div>

                      {/* Social Media Login Buttons */}
                      {/*  */}
                      {/* Forget Password Link */}

                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />

                          {props.t("Forgot your password?")}
                        </Link>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                {/* Sign Up Now */}
                <p>© {new Date().getFullYear()} Aces TPA System</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(Login))

Login.propTypes = {
  history: PropTypes.object,
}
