export const messages = {
  requiredFields: "Please Fill All Required Data",
  successCalc: "Calculated Successfully",
  backToTop: "Back to top",
  duplicatePriceList: "There is already a price list for this service.",
  areYouSure: "Do you want to continue with the uploading of the price list?",
  uploadPremiums: "Do you want to continue with the uploading of the primums?",

  // provider exluded
  providerExluded: "Product Exception based On",
  blackListedProduct:
    "The insurer has placed this provider on a blacklist for this product",
  computePolicy: "Are you sure you want to Compute This Policy?",

  // claims

  dif28:
    "The admission date and the report date show a difference of more than 28 days",
  // duplicatedClaim: "Duplicated Claim Founded For This Beneficiary. Check Claim",
  duplicatedClaim:
    "This Beneficiary has many claims with the same date. Please check Claims ",
  // leave the tab
  unsavedTabChanges:
    "You have unsaved changes , are you sure you want to leave this page?",

  // renew policy
  renewPolicy: "Are you sure you want to Renew This Policy?",
}
