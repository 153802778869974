import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect, useDispatch } from "react-redux"
import { withRouter, Link, useHistory } from "react-router-dom"

// users
import user1 from "../../../assets/images/users/avatar.png"
import { logoutUser } from "store/actions"

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  // const [username, setusername] = useState("Admin")
  const [username, setusername] = useState("")
  const [userPhoto, setUserPhoto] = useState("")

  const dispatch = useDispatch()
  const history = useHistory()

  const localStorageUser = JSON.parse(localStorage.getItem("authUser"))

  useEffect(() => {
    if (localStorageUser) {
      setusername(localStorageUser.first_name)
      setUserPhoto(localStorageUser?.photo?.path)
    }
  }, [localStorageUser.first_name, JSON.stringify(localStorageUser.photo)])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={userPhoto || user1}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link to="/profile" className="dropdown-item">
            <i className="bx bx-user font-size-16 align-middle me-1" />
            <span>{props.t("Profile")}</span>
          </Link>
          <div className="dropdown-divider" />
          <DropdownItem
            tag="button"
            onClick={() => dispatch(logoutUser(history))}
          >
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
