import React, { Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types"

import { Link } from "react-router-dom"
import { Button } from "reactstrap"
import ExportTable from "./ExportTable"
import ShowHideColumns from "./ShowHideColumns"

//i18n
import { withTranslation } from "react-i18next"

// custom hook

import useModuleActions from "hooks/useModuleActions"
import { claimBatchModuleId, productPCIId } from "utils/modulesIds"

import { AysncExportCSVModal } from "components/TP/Common/Modals"
import { buttonsTitles, claimsTypes } from "common/data"

const TableActionsButtons = ({
  t,
  handleAdd,
  addPageLink,
  batchPageLink,
  showOptions,
  setShowOptions,
  columns,
  fullColumns,
  data,
  sheetName,
  sheetHeader,
  getSheetData,
  queryAddOn,
  customCSVHeader,
  subModuleId,
  moduleId,
  addDropDown,
  isBackPermission,
  backPageLink,
  isUnusedMarkPermission,
  handleAddMarkUnused,
  addOnButtons,
  inCludeExportButton,
  asyncExport,
  generateCustomData,
  customCSVData,
  // ref,
}) => {
  const {
    isAddPermission,
    isTableViewPermission,
    isTableExportPermission,
    isTableFilterPermission,
    isBatchUploadPermission,
    isAddClaimReimbursementPermission,
  } = useModuleActions(subModuleId && subModuleId)

  const [isModal, setIsModal] = useState(false)

  return (
    <Fragment>
      <div className="d-flex justify-content-md-end flex-wrap justify-content-start gap-2">
        {/* {addOnButtons ? addOnButtons : null} */}

        {addOnButtons &&
          addOnButtons.length > 0 &&
          addOnButtons.map((addOn, index) => (
            <div className="text-sm-end" key={index + addOn.to}>
              <Link
                className={`btn ${addOn.bg} ${
                  addOn.disabled ? "disabled" : ""
                }`}
                aria-disabled={addOn.disabled}
                tabIndex={addOn.disabled ? "-1" : "0"}
                to={addOn.to ? addOn.to : "#"}
                onClick={addOn.onClick && addOn.onClick}
              >
                <i className={`${addOn.icon} me-1`} />
                {addOn.title}
              </Link>
            </div>
          ))}

        {/* general export to excel */}
        {(subModuleId === productPCIId || asyncExport) && (
          <button
            type="button"
            aria-label="Load CSV Data"
            className="btn btn-secondary"
            onClick={() => setIsModal(true)}
          >
            {t("Export To Excel")}
          </button>
        )}

        {/* For Renewal Policies only */}
        {isBackPermission && backPageLink && (
          <div className="text-sm-end">
            <Link className="btn btn-secondary" to={backPageLink}>
              <i className="mdi mdi-keyboard-backspace me-1" />
              {t("Back")}
            </Link>
          </div>
        )}

        {/* Add Claim Reimbursement Icon Button */}
        {isAddClaimReimbursementPermission && addPageLink && (
          <div className="text-sm-end">
            <Link
              to={{
                pathname: addPageLink,
                state: { type_id: claimsTypes.reimbursement },
              }}
              className="btn me-2 btn-primary"
            >
              <i className="mdi mdi-plus me-1" />
              {isAddClaimReimbursementPermission?.name}
            </Link>
          </div>
        )}

        {/* For Claims only */}
        {isUnusedMarkPermission && handleAddMarkUnused && (
          <div className="text-sm-end">
            <button className="btn btn-info" onClick={handleAddMarkUnused}>
              <i className="mdi mdi-block-helper me-1" />
              {t("Mark as Unused")}
            </button>
          </div>
        )}

        {/* Add Drop Down applied only in Invoice Module right now */}

        {isAddPermission && addDropDown && addDropDown}

        {/* Add Button */}
        {isAddPermission && addPageLink && (
          <div className="text-sm-end">
            <Link to={addPageLink} className="btn me-2 btn-success">
              <i className="mdi mdi-plus me-1" />
              {moduleId === claimBatchModuleId
                ? buttonsTitles.reimbursementBatch
                : buttonsTitles.addNew}
            </Link>
          </div>
        )}

        {/* Add Link */}
        {isAddPermission && !addPageLink && handleAdd && (
          <div className="text-sm-end">
            <Button
              type="button"
              color="success"
              className="btn me-2"
              onClick={handleAdd}
              aria-label="Add New"
            >
              <i className="mdi mdi-plus me-1" />
              {buttonsTitles.addNew}
            </Button>
          </div>
        )}

        {/* Add Button */}
        {isBatchUploadPermission && batchPageLink && (
          <div className="text-sm-end">
            <Link
              to={batchPageLink}
              target="_blank"
              className="btn me-2 btn-info"
            >
              <i className="mdi mdi-cloud-upload me-1" />
              {/* {t("Batch Upload")} */}
              {isBatchUploadPermission?.name}
            </Link>
          </div>
        )}

        {/* Export Button */}
        {(isTableExportPermission || inCludeExportButton) && (
          <div className="text-sm-end">
            <ExportTable
              data={data}
              sheetName={sheetName}
              customCSVHeader={customCSVHeader}
              customCSVData={customCSVData}
              columns={columns}
              // ref={ref}
            />
          </div>
        )}

        {/* Eye Icon button  (show / hide)*/}
        {isTableViewPermission && (
          <div className="text-sm-end">
            <ShowHideColumns
              showOptions={showOptions}
              setShowOptions={setShowOptions}
              fullColumns={fullColumns}
            />
          </div>
        )}

        {/* filter Icon Button */}
        {isTableFilterPermission && (
          <div className="text-sm-end">
            <Button type="button" color="primary" className="btn">
              <i className="mdi mdi-filter font-size-14" />
            </Button>
          </div>
        )}
      </div>

      {isModal && (
        <AysncExportCSVModal
          isOpen={isModal}
          sheetHeader={sheetHeader}
          setIsOpen={setIsModal}
          getSheetData={getSheetData}
          queryAddOn={queryAddOn}
          sheetName={sheetName}
          generateCustomData={generateCustomData}
        />
      )}
    </Fragment>
  )
}

export default withTranslation()(TableActionsButtons)
