import { stakeholderPaths } from "common/data/routePaths"
import SpecialNotesModal from "components/TP/Common/Modals/SpecialNotesModal"
import MultiSkeleton from "components/TP/Common/MultiSkeleton"
import React, { Fragment, Suspense, useState } from "react"
import { Link, useLocation, useParams } from "react-router-dom"
import { Badge } from "reactstrap"
import { beneficaryBenefitTOBId } from "utils/modulesIds"
import MedicalFilesTableModal from "./MedicalFilesTableModal"
import { benefitIds, buttonsTitles, modalsStates } from "common/data"
import NetworkContractsModal from "./NetworkContractsModal"
import ChatModal from "../Common/Modals/ChatModal"

const BeneficiaryLimits = React.lazy(() =>
  import("./TopThreeBoxes/BeneficiaryLimits")
)

const ClaimsBreadcrumbAddons = ({
  beneficiary,
  isLoading,
  benefitId,
  setBeneficiaryLimits,
}) => {
  const { claimId } = useParams()

  const [network, setNetwork] = useState(null)
  // store modal states for all modals
  // in this file
  const [isModal, setIsModal] = useState(false)

  const isVIP =
    beneficiary &&
    beneficiary.client_class &&
    beneficiary.client_class.slug === "memberClass_vip"

  if (isLoading)
    return (
      <div className="bg-white text-danger rounded-1 p-2">
        <MultiSkeleton length={1} />
      </div>
    )

  return (
    !isLoading &&
    beneficiary && (
      <Fragment>
        <div className="d-flex align-items-center">
          {isVIP ? (
            <Badge
              color="primary"
              className="font-size-16 align-self-start ms-2"
            >
              {beneficiary?.client_class?.name}
            </Badge>
          ) : null}
          <ul className="list-unstyled hstack gap-1  ms-2 flex-wrap">
            <li>
              <Link
                className="badge badge-soft-primary fw-semibold py-2 px-2"
                to={`${stakeholderPaths.beneficiaryList}/view/${beneficiary?.id}`}
                target="_blank"
              >
                {buttonsTitles.viewProfile}
              </Link>
            </li>
            <li>
              <button
                aria-label="Beneficiary Medical File"
                className="border-0 badge badge-soft-primary fw-semibold py-2 px-2"
                onClick={() => setIsModal(modalsStates.medicalFilesModal)}
              >
                {buttonsTitles.medicalFile}
              </button>
            </li>
            <li>
              <Link
                className="badge badge-soft-primary fw-semibold py-2 px-2"
                target="_blank"
                to={{
                  pathname: `${stakeholderPaths.beneficiaryList}/view/${beneficiary?.id}#${beneficaryBenefitTOBId}`,
                  state: { id: beneficaryBenefitTOBId },
                }}
              >
                {buttonsTitles.tableofBenefits}
              </Link>
            </li>
            <li>
              <button
                className="badge border-0 badge-soft-primary fw-bold py-2 px-2"
                onClick={() => setIsModal(modalsStates.sepcialNotes)}
              >
                {buttonsTitles.viewNotes}
              </button>
            </li>

            {/* networks */}
            {beneficiary &&
              beneficiary.policy &&
              beneficiary.policy.product &&
              beneficiary.policy.product.product_networks.length > 0 &&
              beneficiary.policy.product.product_networks.map(
                productNetwork => {
                  const isNetworkFoundInPlans =
                    beneficiary?.co_participations?.length > 0 &&
                    beneficiary?.co_participations?.find(
                      plan =>
                        plan?.benefit?.id === productNetwork?.benefit?.id &&
                        plan?.class?.id === productNetwork?.class?.id
                    )
                      ? true
                      : false
                  return isNetworkFoundInPlans ? (
                    <li
                      className="badge badge-soft-primary fw-semibold py-2 px-2"
                      key={`${productNetwork.id}-${productNetwork?.benefit?.id}`}
                      onClick={() => {
                        setIsModal(modalsStates.networkModal)
                        setNetwork({
                          id: productNetwork.id,
                          name: productNetwork?.network?.name,
                        })
                      }}
                    >
                      {productNetwork?.network?.name?.substring(0, 9) + "..."}
                    </li>
                  ) : null
                }
              )}
            {claimId ? (
              <li>
                <button
                  className="badge border-0 badge-soft-primary fw-bold py-2 px-2"
                  onClick={() => setIsModal(modalsStates.chatModal)}
                >
                  {buttonsTitles.chat}
                </button>
              </li>
            ) : null}
          </ul>
        </div>

        {isModal === modalsStates.sepcialNotes && (
          <SpecialNotesModal
            show={isModal === modalsStates.sepcialNotes}
            onCloseClick={() => setIsModal(false)}
            beneficaryId={beneficiary?.id}
            beneficary={beneficiary}
          />
        )}
        {isModal === modalsStates.chatModal && (
          <ChatModal
            show={isModal === modalsStates.chatModal}
            onCloseClick={() => setIsModal(false)}
            beneficaryId={beneficiary?.id}
            // beneficary={beneficiary}
          />
        )}

        {isModal === modalsStates.medicalFilesModal && (
          <MedicalFilesTableModal
            show={isModal === modalsStates.medicalFilesModal}
            onCloseClick={() => setIsModal(false)}
            beneficiaryId={beneficiary?.id}
          />
        )}

        {isModal === modalsStates.networkModal && (
          <NetworkContractsModal
            show={isModal === modalsStates.networkModal}
            onCloseClick={() => setIsModal(false)}
            networkId={network?.id}
            networkName={network?.name}
          />
        )}
      </Fragment>
    )
  )
}

export default ClaimsBreadcrumbAddons
