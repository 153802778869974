import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Modal } from "reactstrap"

// i18n
import { withTranslation } from "react-i18next"
import { TableContainer } from "components/Common"
import { getAllClaims } from "helpers/Claims/ClaimForm"
import {
  NotificationMessage,
  apiErrorrHandler,
  fieldFinder,
  generateColumns,
} from "utils"
import { useSelector } from "react-redux"
import {
  claimAdmissionDetailsId,
  claimDetailsModuleId,
  claimFormModuleId,
} from "utils/modulesIds"
import { buttonsTitles, modalHeaders } from "common/data"
import { SingleRowAction } from "../Common/Tables"
import { claimPaths } from "common/data/routePaths"
import {
  getAllActions,
  getAllSubModules,
} from "helpers/Permissions/permissions_helper"

// this component could be imported in claim
// of beneficiary
// in beneficiary we need to fetch claim permission
// in claim we will have it already so no need to fetch it again
const MedicalFilesTableModal = ({
  show,
  onCloseClick,
  beneficiaryId,
  notInClaim,
  t,
}) => {
  const [claims, setClaims] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({})
  const [columns, setColumns] = useState([])
  const [admissionFields, setAdmissionFields] = useState([])
  const [actionFields, setActionFields] = useState([])

  const { claimFormFields, moduleId, claimTableFields } = useSelector(
    state => ({
      loading: state?.Module?.loading,
      claimTableFields: state?.Module?.actions?.fields,
      moduleId: state?.Module?.actions?.id,
      claimFormFields: state?.Module?.subModules
        ?.find(item => item.id === claimDetailsModuleId)
        ?.sub_modules?.find(item => item.id === claimAdmissionDetailsId)
        ?.fields,
    })
  )

  useEffect(() => {
    if (actionFields.length > 0) {
      const generatedColumns = generateColumns([
        ...actionFields,

        {
          label_name: "Type",
          index_name: "type.name",
          name: "type.name",
        },
        {
          label_name: "Beneficiary Amount",
          index_name: "total_beneficiary_amount",
          name: "total_beneficiary_amount",
        },
        {
          label_name: "Insurer Amount",
          index_name: "payer_total_amount",
          name: "payer_total_amount",
        },
        {
          label_name: "Business Consideration",
          index_name: "business_consideration_amount",
          name: "business_consideration_amount",
        },
        // {
        //   label_name: "Authorization notes",
        //   index_name: "authorization_notes",
        //   name: "authorization_notes",
        // },
        fieldFinder(admissionFields, 712),
        fieldFinder(admissionFields, 406),
        fieldFinder(admissionFields, 407),
        fieldFinder(admissionFields, 415),
        fieldFinder(admissionFields, 401),
        // fieldFinder(admissionFields, 522),
        fieldFinder(admissionFields, 842),
      ])

      setColumns([
        ...generatedColumns
          .filter(
            item =>
              item.accessor !== "beneficiary.name" &&
              item.accessor !== "beneficiary.code" &&
              item.accessor !== "policy.payer.first_name" &&
              item.accessor !== "beneficiary.policy.name" &&
              item.accessor !== "estimation_amount" &&
              item.accessor !== "total_claimed_amount" &&
              item.accessor !== "total_approved_amount"
          )
          .map(column => {
            return { ...column, disableSortBy: true }
          }),
        {
          Header: buttonsTitles.actions,
          accessor: "actions",
          disableFilters: true,
          Cell: cellProps => {
            // Actions Icons in each row with its props
            const row = cellProps.row.original
            return (
              <div className="d-flex gap-2">
                <SingleRowAction
                  actionName="view"
                  target="_blank"
                  to={`${claimPaths.claimForm}/view/${row?.id}`}
                  iconClass="mdi mdi-eye"
                  record={row}
                />
              </div>
            )
          },
        },
      ])
    }
  }, [actionFields])

  const getClaims = async ({
    page,
    per_page,
    sort_by,
    sort_type,
    search_value,
    search_keys,
    search_values,
    // status_id,
    // type_id,
  }) => {
    try {
      setIsLoading(true)
      const res = await getAllClaims({
        page,
        per_page,
        sort_by,
        sort_type,
        search_value,
        search_keys,
        search_values,
        beneficiary_id: beneficiaryId,
        // status_id,
        // type_id: pageURL === claimPaths.claimForm.replace("/", "") ? 233 : 234,
      })
      setClaims(res.items)
      // setClaimIds(res.items.map(item => item.id))
      setPagination({
        size: res.size,
        page: res.page,
        per_page: res.per_page,
        total_pages: res.total_pages,
        total: res.total_size,
      })
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("Error", errorMessage)
    }
  }

  const getClaimPermission = async () => {
    setLoading(true)
    try {
      const level2Res = await getAllActions(claimFormModuleId)
      const level3Res = await getAllSubModules(claimFormModuleId)
      setActionFields(level2Res.item.fields)
      const admissionModulefields = level3Res.item.sub_modules
        ?.find(item => item.id === claimDetailsModuleId)
        ?.sub_modules?.find(item => item.id === claimAdmissionDetailsId)?.fields
      setAdmissionFields(admissionModulefields)
    } catch (error) {
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("error", errorMessage)
    } finally {
      setLoading(false)
    }
  }

  // fetch claim permission if the component isn’t in the claim form

  useEffect(() => {
    if (notInClaim) {
      getClaimPermission()
    } else {
      setActionFields(claimTableFields)
      setAdmissionFields(claimFormFields)
    }
  }, [notInClaim])
  return (
    <Modal
      size="xl"
      isOpen={show}
      toggle={onCloseClick}
      backdrop="static"
      className="modal-fullscreen"
    >
      <div className="modal-header">
        {/* Modal Header Title */}
        <h5 className="modal-title mt-0" id="myModalLabel">
          {modalHeaders.medicalFile}
        </h5>

        {/* close button on the top right section */}
        <button
          aria-label="Close Modal"
          type="button"
          onClick={onCloseClick}
          className="btn-close position-absolute end-0 top-0 m-3"
        ></button>
      </div>

      {/* Modal Body */}
      <div className="modal-body">
        <TableContainer
          removeActionsColumns={true}
          removeActionsButtons={true}
          columns={columns}
          data={claims}
          handleAdd={() => {}}
          pagination={pagination}
          queryAddOn={{ beneficiary_id: beneficiaryId }}
          getData={getClaims}
          loading={isLoading}
          basicTable={true}
        />
      </div>

      <div className="modal-footer">
        <button
          aria-label="Close Modal"
          type="button"
          className="btn btn-secondary"
          onClick={onCloseClick}
        >
          {buttonsTitles.close}
        </button>
      </div>
    </Modal>
  )
}

MedicalFilesTableModal.propTypes = {
  onCloseClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  show: PropTypes.any,
  isSaving: PropTypes.bool,
  children: PropTypes.any,
  beneficiaryId: PropTypes.number,
  // modalTitle: PropTypes.string,
  modalTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

export default withTranslation()(MedicalFilesTableModal)
