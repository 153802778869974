import React, { Fragment, useEffect, useState } from "react"
import { Input, InputGroup, Label } from "reactstrap"
import FormInputError from "./FormInputError"
import { useLocation } from "react-router-dom"
import { NumericFormat } from "react-number-format"
import { isFloat } from "utils/smallUtils"

const FormControll = ({
  id,
  name,
  type,
  value,
  min,
  max,
  inputField,
  label: propLabel,
  inputClass: propInputClass,
  labelClass: propLabelClass,
  placeholder: propPlaceholder,
  error,
  wrapperClassNames,
  children,
  onChange,
  validation,
  includeNumricFormat,
  readOnly,
  horizontalLayout,
  noLabel,
  labelPrefix,
  labelSuffix,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false)

  const label =
    inputField && inputField.label_name ? inputField.label_name : propLabel
  const placeholder =
    inputField && inputField.placeholder
      ? inputField.placeholder
      : propPlaceholder
  const labelClass = `${
    inputField && inputField.label_class ? inputField.label_class : ""
  } ${propLabelClass ? propLabelClass : ""}`

  const inputClass = `${
    inputField && inputField.input_class ? inputField.input_class : ""
  } ${propInputClass ? propInputClass : ""}`

  const { invalid } = rest

  const location = useLocation()
  const { pathname } = location
  // to know if the page link has a view word or not
  const pageType = pathname.split("/")[2]

  return (
    <div className={`${wrapperClassNames ? wrapperClassNames : ""}`}>
      {!noLabel && (
        <Label
          htmlFor={id}
          className={`text-capitalize ${
            inputField?.is_required ? "required-label" : ""
          } ${labelClass ? labelClass : ""}`}
        >
          {labelPrefix && labelPrefix}
          {label}
          {labelSuffix && labelSuffix}
        </Label>
      )}
      <div
        className={horizontalLayout && inputClass}
        // style={{ height: type !== "textarea" && "40px" }}
      >
        {includeNumricFormat ? (
          <NumericFormat
            id={id}
            name={name}
            min={min ? min : undefined}
            max={max ? max : undefined}
            value={value && value}
            placeholder={placeholder}
            thousandSeparator=","
            valueIsNumericString={true}
            allowNegative={false}
            {...rest}
            onValueChange={values => {
              const { value, floatValue } = values
              // validation.setFieldValue(`${name}`, value)
              // onChange(`${name}`, value)
              if (onChange) {
                onChange(`${name}`, floatValue)
              }
            }}
            invalid={pageType === "view" || readOnly ? false : invalid}
            customInput={Input}
            // for decimal sperator
            decimalScale={2}
            fixedDecimalScale={value && isFloat(value) ? true : false}
            // for readonly component
            isAllowed={() => (pageType === "view" || readOnly ? false : true)}
            displayType={pageType === "view" || readOnly ? "text" : "input"}
            renderText={value => (
              <Input
                value={value}
                onChange={undefined}
                className="bg-secondary bg-opacity-10"
                readOnly={true}
              />
            )}
          />
        ) : type === "password" ? (
          <InputGroup>
            <Input
              id={id}
              name={name}
              type={showPassword ? "text" : "password"}
              value={value && value}
              placeholder={placeholder}
              className={`${inputClass ? inputClass : ""} ${
                pageType === "view" ? "border-0" : ""
              } ${readOnly ? "bg-secondary bg-opacity-10" : ""}`}
              {...rest}
              invalid={pageType === "view" || readOnly ? false : invalid}
              onChange={
                onChange && pageType !== "view" && !readOnly
                  ? onChange
                  : () => {}
              }
              // in view page make the input read only or if is readonly prop
              readOnly={pageType === "view" || readOnly}
            />

            {pageType !== "view" ? (
              <div
                className="input-group-text"
                onClick={() => setShowPassword(showPassword => !showPassword)}
              >
                {showPassword ? (
                  <i className="mdi mdi-eye-off-outline font-size-14"></i>
                ) : (
                  <i className="mdi mdi-eye-outline font-size-14"></i>
                )}
              </div>
            ) : null}
            {invalid && pageType !== "view" && !readOnly ? (
              <FormInputError error={error} />
            ) : null}
          </InputGroup>
        ) : (
          <Input
            id={id}
            min={type === "number" && min ? min : undefined}
            max={type === "number" && max ? max : undefined}
            name={name}
            type={type}
            value={value && value}
            placeholder={placeholder}
            className={`${type !== "textarea" && "h-100"} ${
              inputClass ? inputClass : ""
            } ${type === "number" ? "custom-number-input" : ""} ${
              pageType === "view" ? "border-0" : ""
            } ${readOnly ? "bg-secondary bg-opacity-10" : ""}`}
            {...rest}
            invalid={pageType === "view" || readOnly ? false : invalid}
            onChange={
              onChange && pageType !== "view" && !readOnly ? onChange : () => {}
            }
            // in view page make the input read only or if is readonly prop
            readOnly={pageType === "view" || readOnly}
          />
        )}

        {/* Error Message */}
        {invalid && pageType !== "view" && !readOnly ? (
          <FormInputError error={error} />
        ) : null}
      </div>
    </div>
  )
}

export default FormControll
