/* Paths */
import { dashboardPaths } from "common/data/routePaths"
/*
Components
*/
// claim dashboard
import ClaimDashboard from "pages/Dashboard/ClaimDashboard"
// Production Dashboard
import ProductionDashboard from "pages/Dashboard/ProductionDashboard"

const { claimDashboard, productionDashboard } = dashboardPaths
export const DashboardsRoutes = [
  // Claim Dashboard
  { path: claimDashboard, component: ClaimDashboard },
  // Production Dashboard
  { path: productionDashboard, component: ProductionDashboard },
]
