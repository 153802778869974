import React from "react"
import { Col, Row } from "reactstrap"
import {
  AsyncSearchSelect,
  DateRangePicker,
  SearchSelect,
} from "../Common/Forms"
import { buttonsTitles, staticLabels } from "common/data"
import { getAllBenefits } from "helpers/CommonData/benefits"
import { useSelector } from "react-redux"
import { fieldFinder } from "utils"
import { claimsUsersOptions } from "common/data/staticDropdowns"
import { getAllHealthCareProviders } from "helpers/Stakeholders/healthCare"
import { useLocation } from "react-router-dom"
import { claimPaths } from "common/data/routePaths"
import { getAllPayer } from "helpers/Stakeholders/payer"

const ClaimIndexFilters = ({
  advancedFilters,
  setAdvancedFilters,
  loading,
  onFilterClick,
}) => {
  // claim permission level 2
  const fields = useSelector(state => state?.Module?.actions?.fields)

  const location = useLocation()
  const pathname = location.pathname
  return (
    <Row className="align-items-center my-4">
      {/* hide it in claim processing page */}
      {pathname === claimPaths.claimProcessing ? null : (
        <Col md={2}>
          <SearchSelect
            id="20"
            name="created_by"
            label={staticLabels.claimDisplay}
            placeholder="Please Select"
            value={advancedFilters.created_by}
            customOnChange={(selectedValue, { actions }) => {
              setAdvancedFilters(prev => {
                return { ...prev, created_by: selectedValue?.value }
              })
            }}
            onBlur={() => {}}
            disableSearch={true}
            options={claimsUsersOptions}
            mappingLabel="name"
            mappingValue="id"
            wrapperClassNames="mb-3"
          />
        </Col>
      )}
      <Col md={2}>
        <SearchSelect
          id="386"
          name="benefit_id"
          inputField={fieldFinder(fields, 386)}
          value={advancedFilters.benefit_id}
          customOnChange={(selectedValue, label, extra) => {
            setAdvancedFilters(prev => {
              return { ...prev, benefit_id: selectedValue?.id }
            })
          }}
          onBlur={() => {}}
          mappingLabel="name"
          mappingValue="id"
          getOptionsData={getAllBenefits}
          generateFilteredOptions={options =>
            options.filter(item => item.id !== 255)
          }
          urlAddOns={{
            is_active: 1,
            has_slug: 1,
          }}
          wrapperClassNames="mb-3"
          fetchAsync={true}
        />
      </Col>
      <Col md={2}>
        <AsyncSearchSelect
          id="400"
          name="healthcare_provider_id"
          label="Member Name"
          inputField={fieldFinder(fields, 400)}
          value={advancedFilters.healthcare_provider_id}
          customOnChange={(selectedValue, label, extra) => {
            setAdvancedFilters(prev => {
              return { ...prev, healthcare_provider_id: selectedValue?.id }
            })
          }}
          onBlur={() => {}}
          mappingValue="id"
          mappingLabel="first_name"
          searchKey="first_name"
          searchParam="name"
          getOptionsData={getAllHealthCareProviders}
          wrapperClassNames="mb-3"
        />
      </Col>
      <Col md={2}>
        <AsyncSearchSelect
          id="390"
          name="payer_id"
          inputField={fieldFinder(fields, 390)}
          value={advancedFilters.payer_id}
          customOnChange={(selectedValue, label, extra) => {
            setAdvancedFilters(prev => {
              return { ...prev, payer_id: selectedValue?.id }
            })
          }}
          onBlur={() => {}}
          mappingValue="id"
          mappingLabel="first_name"
          searchKey="first_name"
          getOptionsData={getAllPayer}
          wrapperClassNames="mb-3"
        />
      </Col>
      <Col md={3}>
        <DateRangePicker
          id="394"
          name="from_to_admission_date"
          inputField={fieldFinder(fields, 394)}
          value={{
            start_date: advancedFilters.from_admission_date,
            end_date: advancedFilters.to_admission_date,
          }}
          // setDateRange={setDateRange}
          customOnChange={(dates, dateStrings) => {
            setAdvancedFilters(prev => {
              return {
                ...prev,
                from_admission_date: dateStrings[0],
                to_admission_date: dateStrings[1],
              }
            })
          }}
          noBorder={true}
          wrapperClassNames="mb-3"
        />
      </Col>
      <Col md={1}>
        <button
          className="btn btn-secondary w-sm text-center mt-2"
          onClick={onFilterClick}
          disabled={loading}
        >
          {buttonsTitles.show}
        </button>
      </Col>
    </Row>
  )
}

export default ClaimIndexFilters
