import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { Col } from "reactstrap"
import JobListGlobalFilter from "../../components/Common/GlobalSearchFilter"
//i18n
import { withTranslation } from "react-i18next"

// react table
import { useAsyncDebounce } from "react-table"

const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  isJobListGlobalFilter,
  t,
}) => {
  const count = preGlobalFilteredRows.length

  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <Fragment>
      <Col
        md={7}
        // md={4}
      >
        <div className="search-box me-xxl-2 my-0 my-xxl-0 d-inline-block">
          <div className="position-relative">
            <label htmlFor="search-bar-0" className="search-label">
              <span id="search-bar-0-label" className="sr-only">
                Search this table
              </span>
              <input
                onChange={e => {
                  setValue(e.target.value)
                  onChange(e.target.value)
                }}
                id="search-bar-0"
                type="text"
                className="form-control"
                // placeholder={`${count} ${t("records")}...`}
                placeholder={`${t("Search")} ...`}
                value={value || ""}
              />
            </label>
            <i className="bx bx-search-alt search-icon"></i>
          </div>
        </div>
      </Col>
      {isJobListGlobalFilter && <JobListGlobalFilter />}
    </Fragment>
  )
}

GlobalFilter.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
  t: PropTypes.any,
}
export default withTranslation()(GlobalFilter)
