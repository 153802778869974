import { Select } from "antd"
import { indexBooleanOptions } from "common/data/staticDropdowns"
import { AsyncSearchSelect, DatePickerInput } from "components/TP/Common/Forms"
import { generalRequest } from "helpers/General/general_request"
import React, { useMemo } from "react"
import { useSelector } from "react-redux"
import { Input, CustomInput } from "reactstrap"
import { physicianModuleID } from "utils/modulesIds"

export const Filter = ({ column }) => {
  console.log("🚀 ~ file: filters.js:5 ~ Filter ~ column", column)
  return (
    <div style={{ marginTop: 5 }}>
      {column.canFilter && column.render("Filter")}
    </div>
  )
}

export const DefaultColumnFilter = ({ column, search, dataLength }) => {
  const { moduleId, loading } = useSelector(state => ({
    moduleId: state?.Module?.id,
    loading: state?.Module?.loading,
  }))

  // store the module ids in which we can show the dropdown fields
  // as a dropdown instead of text field
  const { supportedModules } = useMemo(() => {
    return { supportedModules: [physicianModuleID] }
  }, [])

  return column.canFilter ? (
    column.fieldType === "boolean" ? (
      <Select
        className={`ColumnFilter ${
          dataLength === 0 ? "bg-secondary bg-opacity-10" : ""
        }`}
        placeholder={`search ...`}
        defaultValue=""
        options={indexBooleanOptions}
        onChange={async e => {
          await column.setFilter(e || undefined)
          search(column || undefined, e)
        }}
        disabled={dataLength === 0 ? true : false}
      />
    ) : column.fieldType === "date" ? (
      <DatePickerInput
        noLabel={true}
        onBlur={() => {}}
        customOnChange={async (date, dateStr) => {
          await column.setFilter(dateStr || undefined)
          search(column || undefined, dateStr)
        }}
        removeIcon={true}
        removeDisable={true}
        clearIcon={true}
        // notCloseOnSelect={true}
        removeOnClose={true}
        placeholder="Select Date"
        readOnly={dataLength === 0 ? true : false}
      />
    ) : supportedModules.includes(moduleId) &&
      column.fieldType === "dropdown" &&
      column?.field_URI &&
      !loading ? (
      <AsyncSearchSelect
        // mappingLabel="name"
        mappingLabel={column?.name?.split(".")?.pop()}
        mappingValue="id"
        // searchKey="name"
        searchKey={column?.name?.split(".")?.pop()}
        noLabel={true}
        placeholder="Search..."
        customOnChange={async selectedValue => {
          await column.setFilter(selectedValue?.name || undefined)
          !selectedValue || selectedValue?.name
            ? search(column || undefined, selectedValue?.name || "")
            : () => {
                return
              }
        }}
        getOptionsData={generalRequest}
        onBlur={() => {}}
        urlAddOns={{ url: column?.field_URI }}
      />
    ) : (
      <Input
        // id="ColumnFilter"
        className={`ColumnFilter ${
          column.disableColumnSearch ? "opacity-0" : "opacity-1"
        } ${dataLength === 0 ? "bg-secondary bg-opacity-10" : ""}`}
        onChange={async e => {
          const inputValue = e.target.value
          await column.setFilter(e.target.value || undefined)
          inputValue.length >= 3 || !inputValue
            ? search(column || undefined, inputValue)
            : () => {
                return
              }
        }}
        placeholder={`search ...`}
        readOnly={dataLength === 0 ? true : false}
      />
    )
  ) : (
    ""
  )
}

export const SelectColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  return (
    <select
      id="custom-select"
      className="form-select"
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined)
      }}
    >
      <option value="">All</option>
      {options.map(option => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}
