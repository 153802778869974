/* Dashboards Module IDs */
export const dashboardsID = 140
export const claimDashboardID = 141
export const productionDashboardID = 142

/* Settings Modules IDS */

export const settingsID = 1

export const regionsModuleID = 6
export const predefinedModuleID = 7
export const companyModuleID = 8
export const taxesModuleID = 9
export const priorityModuleID = 10
export const jobTitlesModuleID = 11
export const jobRolesModuleID = 12
export const usersModuleID = 15
export const notificationTemplatesModuleId = 19
export const notificationTemplateDetailsModuleId = 109
export const fieldsControlModuleId = 67
export const workFlowModuleId = 88
export const claimRuleModuleId = 134

/* Common Data Modules IDS*/
export const commonDataId = 2

export const currenciesModuleID = 26
export const banksModuleID = 27
export const medicalCodesModuleID = 28
export const benefitsModuleID = 29
export const servicesModuleID = 30
export const eventManagerModuleID = 90
export const priorityCodesModuleID = 91
export const pciRulesModuleID = 147
export const pciRulesDetailsID = 148
export const pciRulesInsurersID = 149
export const tobTemplateModuleId = 165

/* Stakeholders Modules IDS*/
export const stakeholdersId = 3

export const reinsurerModuleID = 32
export const physicianModuleID = 31
export const payersModuleID = 33
export const payersDetailsModuleID = 38
export const payersDetailsFirstSectionID = 58
export const payersDetailsSecondSectionID = 59
export const payersTurnaroundTimeModuleID = 143
export const DMPDModuleID = 37
export const payerBenificaryModuleID = 87
export const payerWalletModuleID = 158
export const beneficaryListModuleId = 112
export const beneficaryDetailsModuleId = 113
export const brokerModuleId = 133

/* Newtwork Modules IDs */
export const networkId = 42

export const networksModuleId = 60
export const networkDetailsModuleId = 61
export const networkProvidersModuleId = 62
export const healthCareContractModuleID = 43
export const healthCareContractFirstTabID = 44
export const healthCareContractFirstTabFirstSectionID = 55
export const healthCareContractFirstTabSecondSectionID = 56
export const healthCareContractSecondTabID = 45
export const healthCareContractThirdTabID = 46

export const healthCareProviderModuleID = 34
export const healthCareProviderFirstTabID = 39
export const healthCareProviderSecondTabID = 40
export const healthCareProviderServiceID = 114

/* Productions Modules IDs */
export const productionId = 4

// Payer Agreement
export const PayerAgreementModuleId = 63
export const PayerAgreementFirstTabId = 64
export const PayerAgreementSecondTabId = 65
export const payerAgreementClaimSettlement = 111

// products
export const productModuleId = 66
export const productFirstTabId = 68
export const ProductAdvancedDetailsModuleId = 116
export const productSecondTabId = 69
export const productThirdTabId = 70
export const productFourthTabId = 71
export const productClaimSettlementId = 72
export const productTableOfBenefitsId = 73
export const productPremiumId = 74
export const productUpgradeBenefitTabId = 75
export const productScheduleTemplateId = 92
export const productNetworkId = 124
export const productPCIId = 125
export const productExceptionsId = 153

export const ageGroupId = 89

// Policies
export const policyModuleId = 76
export const policyFirstTabId = 77
export const policyFirstTabFirstSectionId = 78
export const policyFirstTabSecondSectionId = 79
export const policyFirstTabThirdSectionId = 80
export const beneficaryModuleId = 81
export const beneficaryFirstSectionId = 82
export const beneficarySecondSectionId = 83
// beneficary benefit
export const beneficaryBenefitId = 93
// beneficary benefit tabs
export const beneficaryBenefitLimitId = 94
export const beneficaryBenefitTOBId = 95
export const beneficaryBenefitUpgradingBenefitId = 96
export const beneficaryMedicalFormId = 136
export const beneficaryinstallmentsId = 137
export const beneficaryExceptionsId = 154
export const policySettingsModuleId = 85
export const policyCoverageModuleId = 86

/* Claim Modules IDs */
export const claimId = 5
export const claimFormModuleId = 97
export const claimValidationModuleId = 98
export const claimDetailsModuleId = 99
export const claimDischargeDetailsModuleId = 100
export const claimAdmissionDetailsId = 117
export const claimMedicalDetailsId = 118
export const claimAuthorizationDetailsId = 119
export const claimAdditionalDetailsId = 120
export const claimProcessingDetailsId = 121
export const claimItemDetailsId = 122
export const claimBatchModuleId = 103
export const reimbursementClaimModuleId = 123
export const claimProcessingModuleID = 159

export const chronicFormModuleId = 130
export const chronicMainDetailsId = 131
export const chronicServiceItemsId = 132

/* Finance Modules IDS */
export const financeId = 104
export const invoiceModuleId = 105
export const paymentOrderModuleId = 110
export const receiptModuleId = 126
export const policyPaymentModulId = 135
export const commissionPaymentModuleId = 139

/*General Modules IDS*/
export const notificationLogsId = 138
export const productionEventsId = 24

/*Report Modules IDS*/
export const reportsModuleId = 144
export const dynamicReportModuleId = 145
export const blacklistedReportModuleId = 150
export const dueToExpiryReportModuleId = 151
export const overDueReportModuleId = 152
export const agentPoliciesReportModuleId = 155
export const notRenwedReportModuleId = 156
export const VolumeRebateReportModuleId = 162
export const feesByBeneficiaryReportModuleId = 163
export const feesByClaimsReportModuleId = 164
